import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cplakshachandiheights/hero/Hero';
import Landmark from '../../components/common/cplakshachandiheights/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>2/3 BHK Apartments In Goregaon East | Flats in Goregoan East | Lakshachandi Heights</title>
        <meta name="description" content="Explore premium 2 & 3 BHK apartments in Goregaon East by Dynamix Group. Get luxury 2 & 3 BHK flats in Goregaon Mumbai with top-notch amenities at Lakshachandi Heights." />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
