import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
// Video
// import BgVideo from '../../../../assets/video/dynamix_desktop.mp4';
// import BgVideoMobile from '../../../../assets/video/dynamix_mobile.mp4';
import homepage from '../../../../assets/video/homepage.webp'
import bannermobile from '../../../../assets/video/mobilebanner-homenew.jpg'

import { Link } from 'react-router-dom';


// Stylesheet
import './hero.scss';

const textAnimate = {
  offscreen: { opacity: 0, y: 300 },
  onscreen: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.25 } }
};

const Hero = () => {

  const videoRef = useRef(null);

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  };



  return (
    <motion.section className="hero">
      <div className="hero_container">
        {/* video */}
        {/* <video ref={videoRef} autoPlay loop muted playsInline id="hero_video" >
          <source src={window.innerWidth < 720 ? BgVideoMobile : BgVideo} type="video/mp4" />
        </video> */}

        <div className='homebanner'>
          <img src= {window.innerWidth < 720 ? bannermobile : homepage} alt="banner" />
        </div>

        {/* video filter */}
        <div className="video_filter"></div>
        {/* content */}
        <motion.div className="hero_content" initial="offscreen" whileInView="onscreen" variants={textAnimate}>
          {/* {window.innerWidth < 720 ? <><h3 className="hero_sub_title">DISCOVER</h3><h1>PERFECTION</h1></> : ""} */}
          {/* <h3 className="hero_sub_title">DISCOVER</h3> */}
          <h1>Dynamix Group</h1>
          <h2 className="new-hone-hthree letter-space">DRIVEN BY EXCELLENCE, ASSURED BY INTEGRITY</h2>
          {/* <p>
          Welcome To Dynamix Group - Where Vision Meets Construction
          </p> */}
          <Link to={'/current-projects'} onClick={() => window.scrollTo(0, 0)}>
            <button className="btn_primary">Explore Projects</button>
          </Link>
        </motion.div>
      </div>
    </motion.section >
  );
};

export default Hero;
