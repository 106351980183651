import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpjasminetower/hero/Hero';
import Landmark from '../../components/common/cpjasminetower/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";




const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Luxurious Flats in Vasant Vihar Thane, 2 BHK Residential Apartments In Thane | Jasmine Tower</title>
        <meta name="description" content="Looking for a luxurious 2 BHK flat in Vasant Vihar, Thane? Jasmine Tower by Dynamix Group, offers the best 2 BHK residential apartments in Thane with modern living amenities. " />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
