import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpupvantower/hero/Hero';
import Landmark from '../../components/common/cpupvantower/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";


const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>High-End Residential Tower in Malad | 2 BHK Apartments In Malad East - Upvan Tower</title>
        <meta name="description" content="Looking for 2 BHK Apartments in Malad East, Mumbai? We offer high-end residential towers in Malad with modern interiors & amenities. Explore Upvan Tower in Malad by Dynamix Group now!" />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
