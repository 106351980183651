import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/divum/hero/Hero';
import BottomNav from '../../components/common/divum/bottomNav/BottomNav';
import Landmark from '../../components/common/divum/landmark/Landmark';
import Info from '../../components/common/divum/info/Info';
import Experience from '../../components/common/divum/experience/Experience';
import Features from '../../components/common/divum/features/Features';
import Gallery from '../../components/common/divum/gallery/Gallery';
import Walkthrough from '../../components/common/divum/walkthrough/Walkthrough';
import VirtualTour from '../../components/common/divum/virtualTour/VirtualTour';
import Exterior from '../../components/common/divum/exterior/Exterior';
import FloorPlan from '../../components/common/divum/floorPlan/FloorPlan';
import Barcode from '../../components/common/divum/barcode/Barcode';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';
import { Helmet } from "react-helmet";



const DivumPage = () => {
  return (
    <>
      <Helmet>
        <title>Spacious 2 BHK Flats In Goregaon Precinct | Luxury Apartments in Goregaon- Divum</title>
        <meta name="description" content="Divum- The Luxurious 2 BHK flats in Goregaon, Mumbai. Explore premium residential spaces in Goregaon East, featuring modern amenities & spacious homes designed for upscale living. Find your dream apartments in Goregaon now!
" />
      </Helmet>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      <Info />
      <Experience />
      <Features />
      <Gallery />
      <Walkthrough />
      <VirtualTour />
      <Exterior />
      <FloorPlan />
      <Barcode />
      <Contact />
      <Footer />
    </>
  );
};

export default DivumPage;
