import React, { useEffect, useState } from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/home/hero/Hero';
import Testimonials from '../../components/common/home/testimonials/Testimonials';
import Projects from '../../components/common/home/projects/Projects';
// import Tour from '../../components/common/home/tour/Tour';
import Counting from '../../components/common/home/counting/Counting';
import Timeline from '../../components/common/home/timeline/Timeline';
import Blog from '../../components/common/home/blog/Blog';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";
import axios from "axios";

const baseURL = "https://dynamixgroup.co.in/wp-json/wp/v1/blogposts";
const HomePage = () => {

    const [isMobile, setIsMobile] = useState(false)

    //choose the screen size 
    const handleResize = () => {
        console.log(window.innerWidth)
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    // create an event listener
    useEffect(() => {
        window.addEventListener("load", handleResize)
    })

    const script_one = {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Dynamix Group",
        "url": "https://dynamixgroup.co.in/",
        "potentialAction": {
            "@type": "SearchAction",
            "target": "https://dynamixgroup.co.in/current-projects{search_term_string}",
            "query-input": "required name=search_term_string"
        }
    }
    const script_two = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Dynamix Group",
        "url": "https://dynamixgroup.co.in/",
        "logo": "https://dynamixgroup.co.in/static/media/logo-dark-new.37036008d7b64b373d4e30d4c7daa1cf.svg",
        "sameAs": [
            "https://www.facebook.com/thedynamixgroup/",
            "https://www.linkedin.com/company/dynamixgroup/",
            "https://www.youtube.com/@dynamixgroup260",
            "https://www.instagram.com/dynamixgroup/",
            "https://twitter.com/dynamix_group"
        ]
    }

    const script_three = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Dynamix Group",
        "image": "https://dynamixgroup.co.in/static/media/logo-dark-new.37036008d7b64b373d4e30d4c7daa1cf.svg",
        "@id": "",
        "url": "https://dynamixgroup.co.in/contact-us",
        "telephone": "02248970000",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dynamix House, Yashodham, General A.K.Vaidya Marg, Goregaon (East)",
            "addressLocality": "Mumbai",
            "postalCode": "400063",
            "addressCountry": "IN"
        }
    }

    return (
        <>

            <Helmet>
                <title>Real Estate Developer & Builder in Mumbai,Thane & Goa| Property Developers in Mumbai- Dynamix Group</title>
                <meta name="description" content="Find the top real estate developers & builders in Mumbai, Thane & Goa. Discover premium homes in Mumbai by Dynamix Group. We are one of the top property developers & real estate companies in the region. Contact for luxury flats & villas." />
                <script type="application/ld+json">
                    {JSON.stringify(script_one)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(script_two)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(script_three)}
                </script>
            </Helmet>

            <Navigation />
            <Hero />
            <Testimonials />
            <Projects />
            {/* <Tour /> */}
            <Counting />
            <Timeline />
            <Blog />
            <Contact />
            <Footer />
        </>
    )
};

export default HomePage;
