import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/avanya/hero/Hero';
import BottomNav from '../../components/common/avanya/bottomNav/BottomNav';
import Landmark from '../../components/common/avanya/landmark/Landmark';
import Info from '../../components/common/avanya/info/Info';
import Experience from '../../components/common/avanya/experience/Experience';
import Features from '../../components/common/avanya/features/Features';
import Gallery from '../../components/common/avanya/gallery/Gallery';
import Walkthrough from '../../components/common/avanya/walkthrough/Walkthrough';
import VirtualTour from '../../components/common/avanya/virtualTour/VirtualTour';
// import Exterior from '../../components/common/avanya/exterior/Exterior';
import FloorPlan from '../../components/common/avanya/floorPlan/FloorPlan';
import Barcode from '../../components/common/avanya/barcode/Barcode';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';
import { Helmet } from "react-helmet";




const AvanyaPage = () => {
  return (
    <>
      <Helmet>
        <title>Premium Homes In Dahisar East, Mumbai | Luxury Flats & Residence at Dahisar East Avanya</title>
        <meta name="description" content="Looking for premium homes in Dahisar east, Mumbai? We offer 1 & 2 BHK luxurious air-conditioned apartments with fitted kitchens. Explore Avanya for luxury flats & residences at Dahisar East now!
" />
      </Helmet>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      <Info />
      <Experience />
      <Features />
      <Gallery />
      <Walkthrough />
      <VirtualTour />
      {/* <Exterior /> */}
      <FloorPlan />
      <Barcode />
      <Contact />
      <Footer />
    </>
  );
};

export default AvanyaPage;
