import React from 'react'
// Components
import ExpSlider from './ExpSlidergoa'
// Stylesheet
import './experiencegoa.scss'

// Data
import { raetData } from '../../../../constants/expData'

const Experience = () => {
    return (
        <section className='expraet' id='Amenities'>
            <div className='exp_title_container'>
                <div>
                <p>AMENITIES</p>
                <h2>This is a chance to Rethink <br/> Your Idea of  Work-Life Balance s</h2>
                </div>
            </div>
            <ExpSlider expData={raetData} />
        </section>
    )
}

export default Experience
