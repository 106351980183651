import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/astrum/hero/Hero';
import BottomNav from '../../components/common/astrum/bottomNav/BottomNav';
import Landmark from '../../components/common/astrum/landmark/Landmark';
import Info from '../../components/common/astrum/info/Info';
import Experience from '../../components/common/astrum/experience/Experience';
import Features from '../../components/common/astrum/features/Features';
import Gallery from '../../components/common/astrum/gallery/Gallery';
// import Walkthrough from '../../components/common/astrum/walkthrough/Walkthrough';
import VirtualTour from '../../components/common/astrum/virtualTour/VirtualTour';
// import Exterior from '../../components/common/astrum/exterior/Exterior';
import FloorPlan from '../../components/common/astrum/floorPlan/FloorPlan';
import Barcode from '../../components/common/astrum/barcode/Barcode';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';
import { Helmet } from "react-helmet";



const AstrumPage = () => {
  return (
    <>
      <Helmet>
        <title>2 & 3 BHK Residential Flats in Malad | Premium Apartments In Malad East - Astrum</title>
        <meta name="description" content="Explore premium apartments in Malad East at Astrum by Dynamix Group. Get spacious 2 & 3 BHK residential flats in Malad with top-notch amenities in the heart of Malad East." />
      </Helmet>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      <Info />
      <Experience />
      <Features />
      <Gallery />
      {/* <Walkthrough /> */}
      <VirtualTour />
      {/* <Exterior /> */}
      <FloorPlan />
      <Barcode />
      <Contact />
      <Footer />
    </>
  );
};

export default AstrumPage;
