import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cppachsheelheights/hero/Hero';
import Landmark from '../../components/common/cppachsheelheights/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Residential apartments In Kandivali (W) | Panchsheel Heights in Kandivali - Dynamix Group</title>
        <meta name="description" content="Looking for a luxurious residential apartment in Kandivali West, Mumbai? Panchsheel Heights by Dynamix Group offers the best flats in Kandivali with modern living amenities. 
" />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
